import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { GenericEntity } from '@resources/utils/generic-entity';
import { fileToBase64, image2base64, resizeImage, urlToFile } from '@resources/utils/image-utils';
import { autoinject, BindingEngine, computedFrom, Disposable, observable } from 'aurelia-framework';
import { Router, NavigationInstruction } from 'aurelia-router';
import { isGuid, isNullOrUndefined } from '@dts/scriptlib';
import { AuthService } from 'services/auth-service';
import { ImpiloRepository, ImpiloDb } from 'services/impilo-repository';
import { careHomePatientChanged } from 'services/state/actions';
import { Validator } from 'aurelia-validation';
import { AppService } from 'plugins/blister/app-service';

@autoinject()
export class Index extends GenericEntity {

    // abstract get displayName();
    @computedFrom('entity.firstName', 'entity.surname')
    get displayName() {
        const patient = this.entity as ImpiloDb.Tables.Patient;
        const displayName = `${patient.firstName ?? 'New Patient'} ${patient.surname ?? ''}`;
        queueMicrotask(() => careHomePatientChanged(displayName, patient));
        return displayName;
    }

    private readonly files: File[] = [];
    private subscription: Disposable;
    private isDisposed = false;

    private titles: ImpiloDb.Tables.Title[];
    private genders: ImpiloDb.Tables.Gender[];

    @observable private selectedTitle: Partial<ImpiloDb.Tables.Title> = null;
    @observable private selectedGender: Partial<ImpiloDb.Tables.Gender> = null;

    private careHomes: ImpiloDb.Tables.CareHome[] = [];
    private subGroups: ImpiloDb.Tables.SubGroup[] = [];

    @observable private selectedCareHome: Partial<ImpiloDb.Tables.CareHome> = null;
    @observable private selectedSubGroup: Partial<ImpiloDb.Tables.SubGroup> = null;

    constructor(repository: ImpiloRepository, private readonly controller: BootstrapValidationController, validator: Validator,
        private readonly router: Router, private readonly bindingEngine: BindingEngine, private readonly authService: AuthService, private readonly appService: AppService) {
        super(repository, validator);

        this.entity = new ImpiloDb.Tables.Patient();
        const entity = (this.entity as ImpiloDb.Tables.Patient);
        entity.isActive = true;
        entity.pharmacyGuid = this.authService.currentPharmacyGuid();
        entity.created = new Date();
        entity.lastEdited = new Date();
    }

    bind() {
        return this.controller.validate();
    }

    // abstract activate(params, routeConfig, navigationInstruction);
    async activate(params, routeConfig, navigationInstruction: NavigationInstruction) {
        this.titles = await (this.repository as ImpiloRepository).lookups().titles();
        this.genders = await (this.repository as ImpiloRepository).lookups().genders();
        this.careHomes = (await (this.repository as ImpiloRepository).getCareHomes()).results.map((careHome) => {
            careHome.option = careHome.name;
            return careHome;
        });

        if (isGuid(params.guid)) {
            // console.log('activate ', params.guid);
            this.entity = await (this.repository as ImpiloRepository).getPatient(params.guid, true);

            const patient = this.entity as ImpiloDb.Tables.Patient;

            this.appService.unshift(patient.guid, `${patient.firstName} ${patient.surname}`, navigationInstruction);

            // if (this.files.length === 0) {
            if (patient.photo) {
                const base64 = image2base64(patient.photo, 'image/png');
                const file = await urlToFile(base64, patient.photoName);
                this.files.unshift(file);
                if (this.files.length > 1) {
                    this.files.pop();
                }
            }
            // }

            patient.photo = undefined;
            patient.original.photo = undefined;

            this.selectedTitle = patient.title;
            this.selectedGender = patient.gender;
            this.selectedCareHome = patient.careHome;
            this.selectedSubGroup = patient.subGroup;
        }
        else {
            this.selectedCareHome = this.careHomes.find(f => f.name == 'Private Patients');

            // const patient = this.entity as ImpiloDb.Tables.Patient;
            // patient.careHome = this.selectedCareHome as any;

        }


        this.subscription = this.bindingEngine.collectionObserver(this.files)
            .subscribe(this.filesChanged.bind(this));
    }

    selectedTitleChanged(newValue, oldValue) {
        if (this.entity) {
            (this.entity as ImpiloDb.Tables.Patient).title = newValue;
        }
    }

    selectedGenderChanged(newValue, oldValue) {
        if (this.entity) {
            (this.entity as ImpiloDb.Tables.Patient).gender = newValue;
        }
    }

    async selectedCareHomeChanged(newValue, oldValue) {
        if (!isNullOrUndefined(newValue) && !isNullOrUndefined(oldValue) && newValue.guid === oldValue.guid) {
            // do nothing
        } else if (this.entity) {
            (this.entity as ImpiloDb.Tables.Patient).careHome = newValue;

            if (newValue?.guid) {
                // console.log(newValue);
                this.subGroups = (await (this.repository as ImpiloRepository).getSubGroups(newValue.guid)).results.map((subgroup) => {
                    subgroup.option = subgroup.name;
                    return subgroup;
                }).filter((a) => a.isActive).sort((a, b) => a.name.localeCompare(b.name));

                if (isNullOrUndefined(this.selectedSubGroup)) {
                    this.selectedSubGroup = this.subGroups.length > 0 ? this.subGroups[0] : this.selectedSubGroup;
                }

            } else {
                if (this.subGroups) {
                    this.subGroups.splice(0);
                }
            }
        }
    }

    selectedSubGroupChanged(newValue, oldValue) {
        if (!isNullOrUndefined(newValue) && !isNullOrUndefined(oldValue) && newValue.guid === oldValue.guid) {
            // do nothing
        } else if (this.entity) {
            (this.entity as ImpiloDb.Tables.Patient).subGroup = newValue;
        }
    }

    // abstract deactivate();
    deactivate() {
        this.isDisposed = true;
        this.subscription.dispose();
        // this.files.splice(0);
    }

    // abstract escape();
    escape() {
        this.router.navigateToRoute('patients');
    }

    async ok() {
        await this.applyPhotoExistingPatient();
        return super.ok();
    }

    async apply() {
        await this.applyPhotoExistingPatient();
        if (this.canApply) {
            await super.apply();
        }

        const route = `${this.router.generate('patient', { guid: this.entity.guid })}/contact`;
        this.router.navigate(route, { replace: true, trigger: true });

    }

    async applyPhotoExistingPatient() {
        if (!this.entity.isNew()) {
            const entity = this.entity as ImpiloDb.Tables.Patient;
            if (entity.photo !== entity.original.photo) {
                const awaited = await (this.repository as ImpiloRepository).mergePatientPhoto(entity.guid, entity.photo, entity.photoName);
                entity.photo = undefined;
                entity.original.photo = undefined;
                entity.original.photoName = entity.photoName;
            }
        }
    }

    async filesChanged() {
        // console.log(this.files);

        if (!this.isDisposed) {
            if (this.files.length > 0) {
                const resized = await resizeImage({ maxSize: 512, file: this.files[0] });
                const img = await fileToBase64(resized);
                // const img = await fileToBase64(this.files[0]);
                const arr = (img as any).split(',');
                (this.entity as ImpiloDb.Tables.Patient).photo = arr[1];
                (this.entity as ImpiloDb.Tables.Patient).photoName = this.files[0].name;

            } else {
                (this.entity as ImpiloDb.Tables.Patient).photo = null;
                (this.entity as ImpiloDb.Tables.Patient).photoName = null;
                (this.entity as ImpiloDb.Tables.Patient).original.photo = '0x';
            }

            return this.files.length;
        }
        return 0;
    }

    afterMerge() {
        const route = `${this.router.generate('patient', { guid: this.entity.guid })}`;
        this.router.navigate(route, { replace: true, trigger: true });
    }

    private readonly idMatcher = (a, b) => {
        if (a && b) {
            return a.id === b.id;
        }

        return false;
    }
}
