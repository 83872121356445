import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ImpiloRepository } from 'services/repositories';

@autoinject()
export class Index {

    busy = false;
    constructor(private readonly router: Router, private readonly repository: ImpiloRepository ) {
    }   
}
