import { autoinject } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { Router, RouterConfiguration } from 'aurelia-router';

@autoinject()
export class Index {
    private router: Router;

    public configureRouter(config: RouterConfiguration, router: Router) {
        config.title = '';
        config.map([
            { route: '', redirect: 'simplex' },
            { route: 'simplex', name: 'simplex', moduleId: PLATFORM.moduleName('./simplex/index'), title: 'Simple Schedule', nav: true },
            { route: 'complex', name: 'complex', moduleId: PLATFORM.moduleName('./complex/index'), title: 'Complex Schedule', nav: true },
            { route: 'history', name: 'history', moduleId: PLATFORM.moduleName('./history/index'), title: 'History', nav: true },
        ]);
        this.router = router;
    }
}
