import { GenericEntitySearch } from '@resources/utils/generic-entity-search';
import { autoinject, Container, PLATFORM } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Formatters, OnEventArgs, FieldType, Editors, FlatpickrOption, AureliaUtilService, OperatorType } from 'aurelia-slickgrid';
import { IEntity, ResultPager } from 'plugins/data-models';
import { AuthService } from 'services/auth-service';
import { ImpiloDb, ImpiloRepository } from 'services/repositories';
import { ValidationRules, Validator, ValidateEvent } from 'aurelia-validation';
import { CustomFloatEditor, CustomFloatValidator } from '@resources/slickgrid/index';
import { ToastrService } from 'services/toastr-service';
import { DialogService } from 'aurelia-dialog';
import { isGuid } from '@dts/scriptlib';

@autoinject()
export class Index extends GenericEntitySearch {
    private patient: Partial<ImpiloDb.Tables.Patient> = new ImpiloDb.Tables.Patient();
    protected cancelled = false;
    protected notification: any;
    selectedMedication: any[];

    busyPrint = false;
    busyEmail = false;

    notes = '';

    constructor(private readonly repository: ImpiloRepository, private readonly router: Router,
        private readonly authService: AuthService, private readonly toastr: ToastrService,
        private readonly validator: Validator, private readonly aureliaUtilService: AureliaUtilService,
        private readonly dialogService: DialogService) {
        super();

        this.repository.setMapping(ImpiloDb.ProcedureEnum.spgPatientMedication, ImpiloDb.Tables.PatientMedication.map);

        this.pager = new ResultPager(async (page: number, size: number) => {
            // const currentUser = this.authService.currentUser();

            const filter = this.filter.trim();

            if (filter.length >= 0) {
                this.isWaiting = true;
                const response = await this.repository.getPatientMedication(null, this.patient.guid);
                this.isWaiting = false;

                this.gridDataset = response.results.map((entity: ImpiloDb.Tables.PatientMedication) => {
                    (entity as any).status = entity.patientMedicationStatus.description; // for grouping purposes flatten the status
                    (entity as any).statusOrderBy = entity.patientMedicationStatus.orderBy; // for grouping purposes flatten the status
                    (entity as any).orderQuantity = null;

                    return entity;
                });

                return response;
            }
        });

        this.pager.itemsPerPage = 200;
    }

    async attachedDone() {
        if (this.pager) {
            await this.pager.gotoFirst();
        }
    }

    async activate(params, routeConfig, navigationInstruction) {
        if (isGuid(params.guid)) {
            this.patient = await this.repository.getPatient(params.guid, false);
        }
    }

    async ok() {
        return this.cancel();
    }

    async print() {

        this.busyPrint = true;

        let med = '';
        let qty = '';

        for (const item of this.selectedMedication) {
            med += `${item.id},`;
            qty += `${1},`;
        }

        let medqty = med + ';' + qty;

        if (medqty.length > 1) {
            const patient = this.patient as ImpiloDb.Tables.Patient;
            var blob = await (this.repository as ImpiloRepository).downloadGeneratedScript(patient.guid, medqty, this.notes, this.authService.currentUser().userName, `Script-${patient.firstName} ${patient.surname}.pdf`);
        } else {
            this.warning('Please select the medication for the script');
        }

        this.busyPrint = false;
    }

    cancel() {
        this.cancelled = true;

        this.gridDataset.forEach(item => {
            item.cancel();
        });

        this.escape();
    }

    escape() {
        this.router.navigateToRoute('medications');
    }

    canDeactivate(): boolean {
        if (this.cancelled) {
            return true;
        }

        return true;
    }

    warning(msg) {
        const toastr = Container.instance.get(ToastrService);
        toastr.clear(this.notification);
        this.notification = toastr.warning(msg);
    }


    defineGrid() {
        this.gridColumns = [
            { id: 'medication', name: 'Medication', field: '_medication.description', sortable: true, formatter: Formatters.complexObject },
            { id: 'doctor', name: 'Doctor', field: '_doctor.name', sortable: true, formatter: Formatters.complexObject },
        ];

        this.gridOptions = {
            autoResize: {
                containerId: 'container-grid',
                calculateAvailableSizeBy: 'window',
                bottomPadding: 65
            },
            enableGridMenu: false,
            enableCellNavigation: true,
            enableRowSelection: true,
            enableCheckboxSelector: true,
            checkboxSelector: {
                hideSelectAllCheckbox: false
            },
            rowSelectionOptions: {
                selectActiveRow: false,
            }
        };
    }

    // abstract editEntity(entity: IEntity): void;
    editEntity(entity: IEntity) {
        // this.router.navigateToRoute('medication', { guid: entity.guid });
    }

    // abstract newEntity(): void;
    newEntity() {
        // this.router.navigateToRoute('medication', { guid: 'new' });
    }

    // abstract handleRowSelection(event, args);
    handleRowSelection(event, args) {
        const grid = args && args.grid;
        if (grid && Array.isArray(args.rows)) {
            this.selectedMedication = args.rows.map(idx => {
                return grid.getDataItem(idx);
            });
        }
    }

    onCellValidation(e, args) {
        this.toastr.warning(args.validationResults.msg, 'Invalid numeric input');
    }

    onCellChanged(e, args) {

    }
}
