import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { GenericEntity } from '@resources/utils/generic-entity';
import { autoinject, computedFrom, PLATFORM } from 'aurelia-framework';
import { Router, RouterConfiguration } from 'aurelia-router';
import { isGuid } from '@dts/scriptlib';
import { ImpiloDb, ImpiloRepository } from 'services/impilo-repository';
import { careHomePatientChanged } from 'services/state/actions';
import { Validator } from 'aurelia-validation';

@autoinject()
export class Index extends GenericEntity {

    private router: Router;

    constructor(repository: ImpiloRepository, private readonly controller: BootstrapValidationController, validator: Validator) {
        super(repository, validator);
        this.entity = new ImpiloDb.Tables.Patient();
    }

    bind() {
        return this.controller.validate();
    }

    configureRouter(config: RouterConfiguration, router: Router) {
        config.title = '';
        config.map([
            { route: '', redirect: 'search' },
            { route: 'search', name: 'search', moduleId: PLATFORM.moduleName('./search/index'), title: 'Search', nav: true },
            { route: 'bulk-edit/:guid?', name: 'bulk-edit', moduleId: PLATFORM.moduleName('./bulk-edit/index'), title: 'Bulk Edit', nav: false },
            { route: 'drug-interaction/:guid?', name: 'drug-interaction', moduleId: PLATFORM.moduleName('./drug-interaction/index'), title: 'Drug Interaction', nav: false },
            { route: 'medication/:guid?', name: 'medication', moduleId: PLATFORM.moduleName('./medication/index'), title: 'Medication' },
            { route: 'treatment-plan/:guid?', name: 'treatment-plan', moduleId: PLATFORM.moduleName('./treatment-plan/index'), title: 'Treatment Plan', nav: false },
            { route: 'place-order/:guid?', name: 'place-order', moduleId: PLATFORM.moduleName('./place-order/index'), title: 'Place Order', nav: false },
            { route: 'generate-script/:guid?', name: 'generate-script', moduleId: PLATFORM.moduleName('./generate-script/index'), title: 'Generate Script', nav: false },
        ]);
        this.router = router;
    }

    // abstract activate(params, routeConfig, navigationInstruction);
    async activate(params, routeConfig, navigationInstruction) {
        if (isGuid(params.guid)) {
            this.entity = await (this.repository as ImpiloRepository).getPatient(params.guid, false);
        }
    }

    // abstract deactivate();
    deactivate() {
        //
    }

    // abstract escape();
    escape() {
        this.router.navigateToRoute('medications');
    }

    // abstract get displayName();
    @computedFrom('entity.firstName', 'entity.surname')
    get displayName() {
        const patient = this.entity as ImpiloDb.Tables.Patient;
        const displayName = `Medication for ${patient.firstName} ${patient.surname}`;
        queueMicrotask(() => careHomePatientChanged(displayName, patient));
        return displayName;
    }
}
