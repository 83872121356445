import { isGuid } from '@dts/scriptlib';
import { GenericEntitySearch } from '@resources/utils/generic-entity-search';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Formatters, OnEventArgs } from 'aurelia-slickgrid';
import { IEntity, ResultPager } from 'plugins/data-models';
import { AuthService } from 'services/auth-service';
import { ImpiloDb, ImpiloRepository } from 'services/repositories';
import { patientMedicationFullTextAction, patientMedicationSelectedRowAction } from 'services/state/actions';

@autoinject()
export class Index extends GenericEntitySearch {

    private patient: Partial<ImpiloDb.Tables.Patient> = new ImpiloDb.Tables.Patient();

    constructor(private readonly repository: ImpiloRepository, private readonly router: Router, private readonly authService: AuthService) {
        super();

        this.repository.setMapping(ImpiloDb.ProcedureEnum.spgPatientMedication, ImpiloDb.Tables.PatientMedication.map);

        this.pager = new ResultPager(async (page: number, size: number) => {

            // const currentUser = this.authService.currentUser();

            const filter = this.filter.trim();

            if (filter.length >= 0) {

                this.isWaiting = true;
                const response = await this.repository.getPatientMedication(null, this.patient.guid);
                this.isWaiting = false;

                await patientMedicationSelectedRowAction();
                this.setSelectedRow(undefined);

                this.gridDataset = response.results.map((entity: ImpiloDb.Tables.PatientMedication) => {
                    (entity as any).status = entity.patientMedicationStatus.description; // for grouping purposes flatten the status
                    (entity as any).statusOrderBy = entity.patientMedicationStatus.orderBy; // for grouping purposes flatten the status
                    entity.instructions = entity.instructions?.toUpperCase();
                    return entity;
                });

                return response;
            }
        });

        this.pager.itemsPerPage = 200;

    }

    async attachedDone() {
        if (this.pager) {
            await this.pager.gotoFirst();
            this.setGrouping();
        }
    }

    async activate(params, routeConfig, navigationInstruction) {
        if (isGuid(params.guid)) {
            this.patient = await (this.repository).getPatient(params.guid, false);
        }
    }

    defineGrid() {
        this.gridColumns = [
            {
                id: 'edit',
                field: 'edit',
                excludeFromColumnPicker: true,
                excludeFromGridMenu: true,
                excludeFromHeaderMenu: true,
                formatter: Formatters.editIcon,
                minWidth: 30,
                maxWidth: 30,
                onCellClick: async (e: Event, args: OnEventArgs) => {
                    const entity = args.dataContext as ImpiloDb.Tables.PatientMedication;
                    this.router.navigateToRoute('medication', { guid: entity.guid });
                }
            },
            // { id: 'status', name: 'Status', field: 'status', sortable: true },
            { id: 'medication', name: 'Medication', field: '_medication.description', sortable: true, formatter: Formatters.complexObject },
            { id: 'doctor', name: 'Doctor', field: '_doctor.name', sortable: true, formatter: Formatters.complexObject },
            { id: 'scriptValidTo', name: 'Script Expiry', field: 'scriptValidTo', sortable: true, formatter: Formatters.dateIso },
            { id: 'instructions', name: 'Instructions', field: 'instructions', sortable: true }
        ];

        this.gridOptions = {
            autoResize: {
                containerId: 'container-grid',
                calculateAvailableSizeBy: 'window',
                bottomPadding: 65
            },
            enableGridMenu: false,
            enableCellNavigation: true,
            enableRowSelection: true,
            checkboxSelector: {
                hideSelectAllCheckbox: true
            },
            rowSelectionOptions: {
                selectActiveRow: true,
            },
            enableGrouping: true,
        };
    }

    // abstract editEntity(entity: IEntity): void;
    editEntity(entity: IEntity) {
        this.router.navigateToRoute('medication', { guid: entity.guid });
    }

    // abstract newEntity(): void;
    newEntity() {
        this.router.navigateToRoute('medication', { guid: 'new' });
    }

    // abstract handleRowSelection(event, args);
    handleRowSelection(event, args) {
        return patientMedicationSelectedRowAction(args.rows[0]);
    }

    viewTreatmentPlan() {
        this.router.navigateToRoute('treatment-plan', { guid: this.patient.guid });
    }

    viewTreatmentPlanHtml() {

        const newWindow = window.open();

        (this.repository as ImpiloRepository).getTreatmentPlan([this.patient.id], 2).then(f => {
            const treatmentHtml = f.results[0];

            newWindow.document.write(treatmentHtml);

            const check = () => {
                if (newWindow.document) {
                    newWindow.document.title = this.patient.firstName + ' ' + this.patient.surname;
                } else { // if not loaded yet
                    setTimeout(check, 10); // check in another 10ms
                }
            }
            check();
        });
    }

    drugInteraction() {
        this.router.navigateToRoute('drug-interaction', { guid: this.patient.guid });
    }

    placeOrder() {
        this.router.navigateToRoute('place-order', { guid: this.patient.guid });
    }

    generateScript() {
        this.router.navigateToRoute('generate-script', { guid: this.patient.guid });
    }

    bulkEdit() {
        this.router.navigateToRoute('bulk-edit', { guid: this.patient.guid });
    }

    clearGrouping() {
        this.aureliaGrid.dataView.setGrouping([]);
    }

    collapseAllGroups() {
        this.aureliaGrid.dataView.collapseAllGroups();
    }

    expandAllGroups() {
        this.aureliaGrid.dataView.expandAllGroups();
    }

    setGrouping() {
        if (this.aureliaGrid) {
            this.aureliaGrid.dataView.setGrouping({
                getter: 'status',
                formatter: (g) => {
                    return `<strong>${g.value}</strong> <span style="color:green">(${g.count} ${g.count == 1 ? 'item' : 'items'} )</span>`;
                },
                comparer: (a, b) => {
                    const aa = a.rows[0].statusOrderBy;
                    const bb = b.rows[0].statusOrderBy;

                    return (aa == bb ? 0 : (aa > bb ? 1 : -1));
                },
            });
        }
    }
}
